@import url("https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

$agalia-pink: #ff2a97;
.landing-container {
  background-image: url(../Assets/images/Agalia.svg);
  background-repeat: no-repeat;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  overflow-x: hidden;
  background-size: cover;
  height: 100vh;
  background-position: bottom;
}

.jumbotron {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;
  .main-heading {
    font-family: "Lato", sans-serif;
    font-size: 65px;
    font-weight: 600;
    margin-bottom: 30px;

    span {
      font-style: italic;
      color: $agalia-pink;
    }
  }
  .sub-heading {
    font-family: "Karla", sans-serif;
    font-size: 24px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 30px;
  }
  .landing-cta {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.round-btn {
  box-sizing: border-box;
  border-radius: 24px !important;
  box-shadow: none !important;
  padding-left: 4px;
  padding-right: 4px;
  .MuiButton-label {
    font-weight: 400;
    font-family: "Karla", sans-serif;
    font-size: 14px;
    
  }
}

.header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 20px;
  .hrs{
    display: flex;
    justify-content: space-evenly;
    .header-btn{
      font-weight: 400;
    font-family: "Karla", sans-serif;
    font-size: 14px;
    margin-right: 20px;
    }
  }
}
